const changelogs = [
    {
        title: "Посты с картинками, профили участников, страны и многое другое",
        text: `Выпущена первая рабочая версия сайта, спустя более чем 6 месяцев разработки, начиная практически с нуля 4 раза!😌`,
        date: "17.12.2023",
        attach: [
            "https://99px.ru/sstorage/86/2015/12/image_862412152238097572064.gif",
        ]
    },
    {
        title: "Вход без логина и пароля",
        text: `Теперь, чтобы войти в свой аккаунт, достаточно отправить боту код со страницы входа или ввести уже существующий токен авторизации`,
        date: "24.12.2023",
        attach: [
            "https://sun9-54.userapi.com/impg/AamToA_Hyw3gTeY7MzvzPkKNFPHgs8iE5Ihm0g/SvOC67bB5RA.jpg?size=1054x931&quality=96&sign=ce9a33b0ef1f7a9d240ae5a52feec56a&type=album"
        ]
    },
    {
        title: "Иконки, полноэкранный просмотр изображений и багфикс",
        text: `• Добавлены иконки для большинства кнопок
• Новая функция полноэкранного просмотра изображений в постах, профилях участников и стран
• Множество визуальных изменений и исправлены баги
• Добавлена инструкция "Как использовать токен авторизации" который используется на странице входа
• Добавлена инструкция "Где можно опубликовать идеи для сайта и отправить обнаруженные баги"
Инструкции можно найти на странице "Помощь"`,
        date: "28.04.2024",
    },
    {
        title: "Новый домен и страница обновлений",
        text: `У сайта появился свой крутой и красивый домен "hedgehog-rp.ru", а также список обновлений переехал на новую страницу "Обновления", чтобы не засорять ленту с новостями`,
        date: "30.04.2024",
    },
    {
        title: "Поля ввода с иконками",
        text: `• Добавлены иконки внутри полей ввода для поиска по участникам и странам, а так же при редактировании тега страны.
• Добавлены кнопки для отчистки поля поиска по участникам и странам
• Добавлена кнопка авторизации на верхней части экрана в моб. версии
• Уменьшен отступ от краев экрана в моб. версии
• Добавлен заголовок на странице входа
• Новая тема сайта в настройках (простите)`,
        date: "06.05.2024",
    },
    {
        title: "Редактирование профиля и оптимизация",
        text: `• Добавлена функция редактирования профиля
• Сайт оптимизирован и работает быстрее на ~25%😊
• Основное и дополнительное описание страны было заменено на одно общее описание с ограничением в 5000 символов
• Увеличен размер больших полей ввода описания страны и профиля
• Уменьшена длина тега с 32 символов до 16
• Изменено слово "Новость" на "Пост" в некоторых местах
• Добавлен предпросмотр на странице "Участники и "Страны" во время загрузки
• Добавлена кнопка вставки текста в поле токена на странице входа
• Уменьшен размер картинки загрузки сайта в моб. версии`,
        date: "12.05.2024",
    },
    {
        title: "Слайдер с картинками в постах",
        text: `• Добавлен слайдер для переключения картинок в постах (Слайдер появляется при добавлении минимум двух картинок в посте)
• Увеличен размер картинки в посте в моб. версии
• Добавлен задний фон для картинок в слайдере поста
• Добавлен задний фон для кнопки "Поделиться"
• Добавлены 3 крутых рекомендации от знаменитых личностей на странице "О нас"
• Немного увеличена ширина полосы прокрутки страницы в пк версии`,
        date: "21.05.2024",
        attach: [
            "https://cdn3.emoji.gg/emojis/7225-chipichipi-cat.gif",
            "https://media.tenor.com/qJRMLPlR3_8AAAAi/maxwell-cat.gif",
            "https://media.tenor.com/cGzc08rXDCwAAAAj/cat.gif"
        ],
    },
    {
        title: "Список опасных существ",
        text: `Добавлен инструмент под названием "Шиповник" для отображения особо опасных существ
Его вы можете найти на странице "Инструменты"
(Разработано вместе с Данечкой)`,
        date: "22.05.2024",
    },
    {
        title: "Обновление функционала создания, редактирования и просмотра постов",
        text: `• Добавлена загрузка всех постов страны при переходе в профиль страны
• Добавлена возможность редактировать свои посты
• Добавлен новый инструмент "Новость из сообщения в ВК" которая позволяет прямо на сайте найти свое сообщение из беседы и по нему создать пост (новость)
• Добавлен полноэкранный просмотр картинки на страницах: создание/редактирование поста, редактирование профиля участника и страны (Так же добавлен текст указывающий на предпросмотр картинки)
• Изменено отображение списка загруженных картинок при создании/редактировании поста, так же загруженные картинки можно посмотреть в полноэкранном режиме
• Минимальный размер картинки в посте уменьшен с 40px до 20px
• Теперь некоторые инструменты не доступны неавторизованным пользователям
• Добавлен предпросмотр профиля участника и страны во время загрузки сайта
• Добавлен предпросмотр поста на странице с постом если он был открыт по ссылке
• Уменьшен логотип на странице "Авторизации" в пк версии сайта
• Добавлен отступ сверху и снизу страницы у полосы прокрутки страницы и навигации
• Уменьшен отступ между ссылкой страницы (h/...) и первой секцией
• Добавлены кнопки приближения и отдаления картинки в полноэкранном просмотре`,
        date: "12.06.2024",
    },
    {
        title: "Всего по чуть-чуть",
        text: `• Изменен полноэкранный просмотр изображений: добавлены кнопки для увеличения, уменьшения и центрирования, а изображение теперь не выходит за пределы области просмотра
• Увеличен размер окна для полноэкранного просмотра изображений
• В навигацию добавлена кнопка "Карта мира" для перехода на интерактивную карту
• Добавлено описание почти ко всем кнопкам, которое появляется при наведении курсора или удержании кнопки на телефоне
• Изменен текст кнопки с "Отчистить" на "Отмена" в блоках с поиском 
• Изменен цвет подсказки в поле ввода текста на немного более светлый
• Изменен цвет длинного текста в постах и других местах на немного более темный
• Изменено отображение даты создания поста на странице создания поста
• Изменена страница настроек, так же теперь все настройки синхронизируются между устройств
• (Экспериментально) Добавлена возможность выбрать фон сайта или отключить его на странице настроек
• (Экспериментально) Добавлена кнопка "Наверх страницы" на ПК-версии страниц: Главная, Новости, Профиль страны, Обновления. На странице настроек можно отключить эту кнопку`,
        date: "17.06.2024",
    },
    {
        title: "Игральная кость",
        text: `Добавлен инструмент под названием "Игральная кость", который позволяет бросить кубик и получить случайное число
• Удачу (+-5) можно добавить только для броска кубика d20
• Результат с измененной удачей не может превышать 20 или быть меньше 1
• Для отправки результатов в беседу используется имя с сайта`,
        date: "22.06.2024",
    },
    {
        title: "Дополнительная информация в постах",
        text: `• Изменено отображение даты и время публикации поста
• Посты 3 сезона отмечены как "Сезон 3"
• Добавлено отображение автора поста вместо названия страны
• Добавлено изменение автора поста при создании и редактировании поста (По умолчанию название страны)
• Добавлено описание для кнопки "Наверх страницы"
• Скрыт текст кнопок под постом на маленьких экранах`,
        date: "09.07.2024",
    },
    {
        title: "Изменение главной страницы",
        text: `• На главной странице блоки "Новое видео на канале" и "Последние новости" поменялись местами
• Блок "Самое актуальное" на главной странице переименован в "Последние новости"
• Блок "Новое на сайте" на главной странице переименован в "Обновления на сайте"
• Добавлен предпросмотр на главной странице для блока с новостями и обновлениями
• Добавлен предпросмотр на странице "Новости"
• Добавлен предпросмотр на странице "Обновления"`,
        date: "01.08.2024",
    },
    {
        title: "Ежиное ТВ",
        text: `• Добавлен новый сайт для просмотра всех серий "Ежиных Хроник" - https://tv.hedgehog-rp.ru
• В навигацию добавлена кнопка "Ежиное ТВ" для перехода на Ежиное Телевидение
• Блок "Новое видео на канале" на главной странице переименован в "Новое видео на Ежином ТВ" и ведет теперь на Ежиное Телевидение`,
        date: "04.08.2024",
    },
    {
        title: "Сохранение информации о постах",
        text: `Теперь информация о посте сохраняется автоматически, поэтому, если вы закроете страницу и затем восстановите её, либо обновите её во время создания или редактирования поста, последние внесённые изменения будут автоматически загружены на страницу редактирования`,
        date: "25.08.2024",
    },
    {
        title: "Куки",
        text: `Теперь токен авторизации будет сохраняться в куки, надеюсь это снизит вероятность когда браузер очищает данные о входе. Также уменьшен размер кнопок в навигации на ПК и телефоне`,
        date: "12.09.2024",
    },
]

// Возвращаем посты в обратном порядке
export default changelogs.reverse()